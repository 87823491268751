<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success">
                            <h4 class="col-10">Administrar el zonal en <div class="badge badge-light">{{ nombreEmpresa }}</div></h4>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                         <div class="modal-body m-0" v-if="success">
                            <h5 class="text-success text-center mt-1">✓ Zonal asignado</h5>
                        </div>
                        <div class="modal-body" v-if="!success">
                            <div class="alert alert-info">El zonal asignado actualmente es: <div class="badge badge-light">{{zonalActual}}</div></div>
                            <!-- Form -->
                            <label for="selectEmpleado" class="form-label">Seleccioná el zonal</label>
                            <div class="row align-items-center mb-4">
                                <!-- Select -->
                                <div class="col-12">    
                                   <!-- <Select2 class="w-100" v-model="legajo" :options="empleados" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /> -->
                                   <Select2 class="w-100 input-error" v-model="legajo" :options="empleados" :settings="{settingOption: value, width:'100%', theme: 'classic' }" @select="guardarSeleccion($event)"/>
                                </div>
                                <!-- Button Add -->
                                <!-- <div class="col-2">
                                    <button
                                        type="button"
                                        class="btn btn-success w-100"
                                        @click="asignarEmpleado()"
                                    >
                                        Añadir
                                    </button>
                                </div> -->
                                <!-- Add error -->
                                <p class="text-danger pl-3" v-if="errors.nombre">· {{ errors.nombreMensaje }}</p>
                            </div>                        
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                            <button type="button" class="btn btn-light" @click.prevent="close()">
                                Cancelar
                            </button>
                            <button
                                type="button"
                                @click.prevent="asignarZonal()"
                                class="btn btn-success"
                            >
                                Asignar zonal
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
import firebase from "firebase/app";
import "firebase/database";

import $ from 'jquery';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

export default {
    components: {Select2},
    // components: {Select2},
    name: "ModalZonal",
    props: ['empresa','nombreEmpresa'],
    data(){
        return {
            legajo: '',
            dni: '',
            nombre: '',
            errors: {
                nombre: false,
                nombreMensaje: null
            },
            triggers: {
                carga: true,
                sinEmpleados: false,
                empleadosConCategorias: false,
            },
            success: false,
            empleadosEnOperacion: [],
            empleados: [],
            empleadosFullData: [],
            // distribucion:[],
            // categorias: [],
            // categoriasAsignadas: false,
            seReasigno: false,
            enLegales: [],
            zonalActual:null,

        }
    },
    mounted(){
        // Obtener personal
        axios.get("https://aplicativos.firebaseio.com/zonal/"+ this.empresa +".json?print=pretty")
        .then(zonalRes=>{
            this.zonalActual = (zonalRes.data!=null) ? zonalRes.data.nombre : 'NINGUNO';
        });

        axios
        .get('https://auth.foodservice.com.ar/?type=personal_mysql_b&access_token=1234567')
        .then(response => {
            if (response.status == 200) {
                axios.get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
                .then(legalResponse=>{
                    const legales = legalResponse.data;

                    const data = response.data;
                    // Para usar en otras operaciones, como obtener el DNI por ejemplo.
                    this.empleadosFullData = data;
                    /**
                     *  Select2 requiere un array [{id:1,text:nombre}]
                     * 	Se mapea el response a traves de las claves del objeto JSON
                     */			
                    this.empleados = Object.keys(data).flatMap((key)=>{
                        if(legales[data[key].leg_numdoc] != null) {
                            this.enLegales.push(data[key].leg_numero);
                            return []
                        } else {
                            return {
                                id: data[key].leg_numero,
                                text: data[key].leg_apellido + " " + data[key].leg_nombre
                            }
                        }
                    });	
                    console.log(this.empleados);				
                })

            }
        })
        .catch((error) => {
            console.log(error);
        });                         
    },
    methods: {
        guardarSeleccion({id, text}){
            this.legajo = id;
            this.nombre = text;
            this.errors.nombre = false;
            console.log(this.legajo);
            console.log(this.nombre);
            console.log(this.empresa);
        },
        limpiarSeleccion(){
            this.legajo = '';
            this.nombre = '';
        },
        asignarZonal(){
            // Comprobar que se selecciono un empleado
            if (!this.legajo){
                this.errors.nombre = true;
                this.errors.nombreMensaje = "Antes de añadir selecciona un empleado";
                return null
            } 
            
            // Pushea el empleado seleccionado en la empresa seleccionada
            firebase.database().ref('zonal/' + this.empresa).update({
                legajo:this.legajo,
                nombre:this.nombre
            }, (error) => {
                if (error) {
                    console.log(error);
                    alert("error: no se pudo asignar el empleado en la operacion /n verificá la conexión a internet o reintentalo");
                } else {
                    console.log("empleado asignado asignado a la operación: " + this.empresa);
                    this.seReasigno = true;
                    this.success = true;
                    setTimeout(() => {
                        this.close()
                    }, 1500);
                }
            });
            this.limpiarSeleccion();
        },
        close(){
            this.$emit('cerrar-modal-zonal', this.seReasigno);
        },
        estaEnLegales(legajo){
            let esta = false;
            Object.keys(this.enLegales).forEach((item)=>{                
                if(this.enLegales[item]==legajo) esta = true;
            })
            return esta;
        }
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>